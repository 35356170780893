// ** Import Packages **
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { AxiosRequestConfig } from 'axios';

// ** Axios **
import { axiosBaseQuery } from 'axios/axios';

// ** API **
import { timelineApi } from './timelineApi';
import { accountApi } from './accountApi';
import { ModuleNames } from 'constant/permissions.constant';
import { contactApi } from './contactApi';
import { leadApi } from './leadApi';
import { activityApi } from './activityApi';
import { trashApi } from './trashApi';

const NOTE_API_BASE_PATH = '/notes';

export const noteApi = createApi({
  reducerPath: 'noteApi',
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getNotes: builder.query<any, AxiosRequestConfig<any>>({
      query: ({ data, ...rest }) => {
        return {
          url: `${NOTE_API_BASE_PATH}`,
          method: 'GET',
          data,
          extraOptions: { ...rest },
        };
      },
      providesTags: () => {
        return [{ type: 'NOTE', id: 'LIST' }];
      },
    }),

    getAllNotes: builder.query<any, AxiosRequestConfig<any>>({
      query: ({ data, ...rest }) => {
        return {
          url: `${NOTE_API_BASE_PATH}/get-data`,
          method: 'POST',
          data,
          extraOptions: { ...rest },
        };
      },
      providesTags: () => {
        return [{ type: 'NOTE', id: 'LIST' }];
      },
    }),

    getNoteById: builder.query<any, AxiosRequestConfig<any> & { id: number }>({
      query: ({ id, data, ...rest }) => {
        return {
          url: `${NOTE_API_BASE_PATH}/${id}`,
          method: 'GET',
          data,
          extraOptions: { ...rest },
        };
      },
      providesTags: (_arg1, _arg2, { id }) => {
        return [{ type: 'NOTE', id }];
      },
    }),
    addNote: builder.mutation<
      any,
      AxiosRequestConfig<any> & {
        modelName: ModuleNames;
      }
    >({
      query: ({ data, ...rest }) => {
        return {
          url: `${NOTE_API_BASE_PATH}`,
          method: 'POST',
          data,
          extraOptions: { ...rest },
        };
      },
      async onQueryStarted(arg, api) {
        try {
          const { queryFulfilled } = api;
          const updatedInfo = await queryFulfilled;
          if (updatedInfo?.data) {
            api.dispatch(
              timelineApi.util.invalidateTags([
                { type: 'TIMELINE', id: 'LIST' },
              ])
            );
            api.dispatch(noteApi.util.resetApiState());
            if (arg?.modelName === ModuleNames.ACCOUNT) {
              api.dispatch(accountApi.util.resetApiState());
            } else if (arg?.modelName === ModuleNames.CONTACT) {
              api.dispatch(contactApi.util.resetApiState());
            } else if (arg?.modelName === ModuleNames.LEAD) {
              api.dispatch(leadApi.util.resetApiState());
            } else if (arg?.modelName === ModuleNames.DEAL) {
              api.dispatch(leadApi.util.resetApiState());
            } else if (arg?.modelName === ModuleNames.ACTIVITY) {
              api.dispatch(activityApi.util.resetApiState());
            } else {
              //
            }
          }
        } catch (error) {
          //
        }
      },
    }),
    updateNote: builder.mutation<any, AxiosRequestConfig<any> & { id: number }>(
      {
        query: ({
          id,
          data,
          ...rest
        }: AxiosRequestConfig<any> & { id: number }) => {
          return {
            url: `${NOTE_API_BASE_PATH}/${id}`,
            method: 'PUT',
            data,
            extraOptions: { ...rest },
          };
        },
        async onQueryStarted(arg, api) {
          try {
            const { queryFulfilled } = api;
            const updatedInfo = await queryFulfilled;
            if (updatedInfo?.data) {
              api.dispatch(
                timelineApi.util.invalidateTags([
                  { type: 'TIMELINE', id: 'LIST' },
                ])
              );
              api.dispatch(noteApi.util.resetApiState());
            }
          } catch (error) {
            //
          }
        },
      }
    ),

    restoreNotes: builder.mutation<any, AxiosRequestConfig<any>>({
      query: ({ data, ...rest }: AxiosRequestConfig<any>) => {
        return {
          url: `${NOTE_API_BASE_PATH}/restore`,
          method: 'POST',
          data,
          extraOptions: { ...rest },
        };
      },
      async onQueryStarted(arg, api) {
        try {
          const { queryFulfilled } = api;
          const updatedInfo = await queryFulfilled;
          if (updatedInfo?.data) {
            api.dispatch(
              timelineApi.util.invalidateTags([
                { type: 'TIMELINE', id: 'LIST' },
              ])
            );
            api.dispatch(
              trashApi.util.invalidateTags([{ type: 'TRASH', id: 'LIST' }])
            );
            api.dispatch(noteApi.util.resetApiState());
          }
        } catch (error) {
          //
        }
      },
    }),

    destroyNote: builder.mutation<any, AxiosRequestConfig<any>>({
      query: ({ data, ...rest }: AxiosRequestConfig<any>) => {
        return {
          url: `${NOTE_API_BASE_PATH}/destroy`,
          method: 'DELETE',
          data,
          extraOptions: { ...rest },
        };
      },
      async onQueryStarted(arg, api) {
        try {
          const { queryFulfilled } = api;
          const updatedInfo = await queryFulfilled;
          if (updatedInfo?.data) {
            api.dispatch(
              timelineApi.util.invalidateTags([
                { type: 'TIMELINE', id: 'LIST' },
              ])
            );
            api.dispatch(
              trashApi.util.invalidateTags([{ type: 'TRASH', id: 'LIST' }])
            );
            api.dispatch(noteApi.util.resetApiState());
          }
        } catch (error) {
          //
        }
      },
    }),

    deleteNote: builder.mutation<
      any,
      AxiosRequestConfig<any> & { id: number; modelName: ModuleNames }
    >({
      query: ({
        id,
        data,
        ...rest
      }: AxiosRequestConfig<any> & { id: number }) => {
        return {
          url: `${NOTE_API_BASE_PATH}/${id}`,
          method: 'DELETE',
          data,
          extraOptions: { ...rest },
        };
      },
      async onQueryStarted(arg, api) {
        try {
          const { queryFulfilled } = api;
          const updatedInfo = await queryFulfilled;
          if (updatedInfo?.data) {
            api.dispatch(
              timelineApi.util.invalidateTags([
                { type: 'TIMELINE', id: 'LIST' },
              ])
            );
            api.dispatch(noteApi.util.resetApiState());
            if (arg?.modelName === ModuleNames.ACCOUNT) {
              api.dispatch(accountApi.util.resetApiState());
            } else if (arg?.modelName === ModuleNames.CONTACT) {
              api.dispatch(contactApi.util.resetApiState());
            } else if (arg?.modelName === ModuleNames.LEAD) {
              api.dispatch(leadApi.util.resetApiState());
            } else if (arg?.modelName === ModuleNames.DEAL) {
              api.dispatch(leadApi.util.resetApiState());
            } else if (arg?.modelName === ModuleNames.ACTIVITY) {
              api.dispatch(activityApi.util.resetApiState());
            } else {
              //
            }
          }
        } catch (error) {
          //
        }
      },
    }),
  }),

  tagTypes: ['NOTE'],
});

export const {
  useLazyGetNotesQuery,
  useLazyGetAllNotesQuery,
  useLazyGetNoteByIdQuery,
  useAddNoteMutation,
  useUpdateNoteMutation,
  useRestoreNotesMutation,
  useDeleteNoteMutation,
  useDestroyNoteMutation,
} = noteApi;
