// ** external packages **
import { Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

// * components *
import DashBoardLayout from 'pages/Dashboard/components/DashboardLayout';
import RequiresAuth from 'pages/auth/components/RequiresAuth';
import RequiresUnAuth from 'pages/auth/components/RequiresUnAuth';
import NotFound from 'pages/NotFound';
import PageLoader from 'components/loader/PageLoader';
import SiteLoader from 'components/loader/SiteLoader';

// * others *
import {
  publicRoutes,
  generalRoutes,
  privateRoutes,
} from 'router/routes/crm.routes';
import {
  ModuleNames,
  BasicPermissionTypes,
} from 'constant/permissions.constant';
import TwoFactorProvider from 'pages/auth/components/TwoFactorProvider';
import { getCSRFExpiryTime } from 'redux/slices/commonSlice';
import { useSelector } from 'react-redux';
import { useGetAndSetCSRFtoken } from 'hooks';

export interface RouteAttribute {
  path: string;
  component: JSX.Element;
  name?: string;
  module?: ModuleNames;
  type?: BasicPermissionTypes;
}

const RouteComponent = () => {
  const location = useLocation();
  const { getAndSetCSRFtoken } = useGetAndSetCSRFtoken();
  const csrfExpiryTime = useSelector(getCSRFExpiryTime);
  useEffect(() => {
    const date = csrfExpiryTime || new Date();
    const shouldCall = new Date(date).getTime() - new Date().getTime() <= 0;

    if (shouldCall) {
      getAndSetCSRFtoken();
    }
  }, [location.pathname]);
  return (
    <Routes>
      {generalRoutes &&
        generalRoutes.length > 0 &&
        generalRoutes.map((route: RouteAttribute) => {
          return route.component ? (
            <Route
              key={route.path}
              path={route.path}
              element={
                <TwoFactorProvider pathName={route.path}>
                  <Suspense fallback={<SiteLoader />}>
                    {route.component}
                  </Suspense>
                </TwoFactorProvider>
              }
            />
          ) : null;
        })}

      {publicRoutes &&
        publicRoutes.length > 0 &&
        publicRoutes.map((route: RouteAttribute) => {
          return route.component ? (
            <Route
              key={route.path}
              path={route.path}
              element={
                <RequiresUnAuth>
                  <Suspense fallback={<SiteLoader />}>
                    {route.component}
                  </Suspense>
                </RequiresUnAuth>
              }
            />
          ) : null;
        })}

      {privateRoutes &&
        privateRoutes.length > 0 &&
        privateRoutes.map((route: RouteAttribute) => {
          return route.component ? (
            <Route
              key={route.path}
              path={route.path}
              element={
                <RequiresAuth module={route.module} type={route.type}>
                  <DashBoardLayout
                    headerTitle={route.name || 'Welcome Brooks!'}
                  >
                    <Suspense fallback={<PageLoader />}>
                      {route.component}
                    </Suspense>
                  </DashBoardLayout>
                </RequiresAuth>
              }
            />
          ) : null;
        })}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default RouteComponent;
