// ** Import Packages ** //
import { ChangeEventHandler, Dispatch, SetStateAction } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

// ** Components ** //
import Icon from 'components/Icon';

// ** Others ** //
import { generateCustomizeDate } from 'pages/Activity/helper/dateAndTime.helper';
import {
  AddActivityFormFields,
  miniCalenderDataType,
} from 'pages/Activity/types/activity.types';
import { TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { ChangeEventArgs } from '@syncfusion/ej2-react-inputs';

type ActivityTimePickerPropsType = {
  changeSchedulerDataSourceOnNavigation: (
    {
      startDate,
      endGapDate,
      isStartTimeUpdated,
    }: {
      startDate: Date;
      endGapDate: Date;
      isStartTimeUpdated?: boolean;
    },
    IsAllDay: boolean
  ) => void;
  setMiniCalenderData: Dispatch<SetStateAction<miniCalenderDataType>>;
};

const ActivityTimePicker = (props: ActivityTimePickerPropsType) => {
  const { changeSchedulerDataSourceOnNavigation, setMiniCalenderData } = props;

  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
  } = useFormContext<AddActivityFormFields>();

  const useWatchData = useWatch({
    control,
  });

  const startTimeWatch = useWatchData?.start_time || '';
  const allDayWatch = useWatchData?.all_day;

  const timePickerOnChange = (e: ChangeEventArgs) => {
    if (e.isInteracted && e?.value) {
      const startDate = generateCustomizeDate(
        new Date(getValues('start_date')),
        new Date(e?.value)
      );
      const endGapDate = generateCustomizeDate(
        new Date(getValues('start_date')),
        new Date(e?.value),
        getValues('duration')
      );
      changeSchedulerDataSourceOnNavigation(
        {
          startDate,
          endGapDate,
          isStartTimeUpdated: true,
        },
        false
      );
      setValue('start_date', startDate.toISOString());
      setValue('start_time', startDate.toISOString());
    }

    if (e.value === null) {
      setMiniCalenderData((prev) => ({
        ...prev,
        IsAllDay: true,
      }));
      setValue('all_day', true);
      clearErrors('start_time');
      setValue('start_time', '');
    } else {
      setValue('all_day', false);
    }
  };

  const allDayOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.checked) {
      setValue('all_day', true);

      setMiniCalenderData((prev) => ({
        ...prev,
        IsAllDay: true,
      }));
    } else {
      setValue('all_day', false);
    }
  };

  return (
    <>
      <div className="w-[180px] mt-[4px] pr-[10px] relative md:w-full md:pr-0 md:mb-[12px] sm:w-[calc(50%_-_6px)] sm:ml-[6px] sm:mb-[15px]">
        <label className="if__label if__label__blue flex flex-wrap opacity-0 md:hidden sm:flex">
          <Icon className="mr-[5px]" iconType="watchFilled" />
          Start
        </label>
        <label className="if__label if__label__blue absolute top-0 right-[-41px]">
          All Days
        </label>
        <div className="flex">
          <div className="mb-[20px] md:mb-[10px] sm:mb-[5px]">
            <TimePickerComponent
              step={15}
              strictMode
              value={new Date(startTimeWatch)}
              change={timePickerOnChange}
              placeholder="EX: 10:30 AM"
              enabled={!allDayWatch}
              scrollTo={new Date(startTimeWatch)}
              openOnFocus
            />
            {errors.start_time && (
              <p className="ip__Error">{errors.start_time.message}</p>
            )}
          </div>
        </div>
      </div>
      <div className="ip__Checkbox toggleSwitch mt-[44px] mr-[15px]">
        <input
          type="checkbox"
          checked={allDayWatch}
          onChange={allDayOnChange}
        />
        <label className="rc__Label">All Day</label>
      </div>
    </>
  );
};

export default ActivityTimePicker;
