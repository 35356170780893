import { ALLOWED_MIME_TYPES } from 'constant';

export const useFileUpload = ({
  clearError,
  setImage,
  setErrorForHook,
  image,
  setValueImageEdit,
}: any) => {
  const onFileSelect: React.ChangeEventHandler<HTMLInputElement> | undefined = (
    e
  ) => {
    const file = e.target.files?.[0];
    e.target.value = '';
    if (file && ALLOWED_MIME_TYPES.includes(file.type)) {
      if (file.size < 10 * 1024 * 1024) {
        clearError(image);
        if (setValueImageEdit) {
          setImage(image, file);
        } else {
          setImage(file);
        }
      } else if (image === 'organization_logo' || image === 'profile_image') {
        setErrorForHook(image, {
          type: 'custom',
          message: 'Please upload image less than 2MB',
        });
      } else {
        setErrorForHook(image, {
          type: 'custom',
          message: 'Please upload image less than 10 MB',
        });
      }
    } else {
      setErrorForHook(image, {
        type: 'custom',
        message: 'Only PNG,JPEG,JPG are allowed',
      });
    }
  };

  return { onFileSelect };
};
