// ** external packages **
import { AxiosRequestConfig } from 'axios';

// ** axios hooks **
import {useAxiosGet as useGetUserTokenMutation, useAxiosPost as usePostAuthMutation } from 'hooks/useAxios';

const CHECK_FIELD_IS_UNIQUE = '/check-field-is-unique';
const CSRF_API_BASE_PATH = '/csrf';

export const useFieldIsUniqueAPI = () => {
  // ** custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = usePostAuthMutation();

  const fieldIsExistsAPI = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${CHECK_FIELD_IS_UNIQUE}`, data, config);
  };

  return { fieldIsExistsAPI, isLoading, isError, isSuccess };
};

// ** get CSRF token ** 

export const useGetCSRFTokenAPI=()=>{
  const [callApi, { isLoading, isError, isSuccess }] =
  useGetUserTokenMutation();
const getCSRFTokenAPI = async (
  config: AxiosRequestConfig<object> = {}
) => {
  return callApi(`${CSRF_API_BASE_PATH}`, config);
};
return { getCSRFTokenAPI, isLoading, isError, isSuccess };
}
