import Modal from 'components/Modal';
import AuthCard from '../components/AuthCard';
import AuthLayout from '../components/AuthLayout';
import TowFactorVerifyCard from './TowFactorVerifyCard';

interface Props {
  isContinueLoggedIn?: boolean;
}

const TowFactorVerify = ({ isContinueLoggedIn }: Props) => {
  return isContinueLoggedIn ? (
    <Modal showHeader={false} visible showFooter={false}>
      <TowFactorVerifyCard />
    </Modal>
  ) : (
    <AuthLayout>
      <AuthCard showHeader={false}>
        <TowFactorVerifyCard />
      </AuthCard>
    </AuthLayout>
  );
};

export default TowFactorVerify;
