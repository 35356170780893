// ** import packages **
import { yupResolver } from '@hookform/resolvers/yup';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

// ** components **
import MarkAsDoneForm from '../Form/MarkAsDoneForm';

// ** Schema **
import { markAsDoneActivitySchema } from '../../validation-schema/activity.schema';

// ** Type **
import { MarkAsDoneFormFields } from '../../types/activity.types';
import {
  useLazyGetActivityByIdQuery,
  useUpdateActivityMutation,
} from 'redux/api/activityApi';

// ** Helper **
import { getDefaultActivityResult } from 'pages/Setting/module-setting/Activity/ActivityType/helper/activityType.helper';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import ScheduleFollowUpModal from './ScheduleFollowUpModal';
import {
  AuthProviderConnectURLS,
  TokenProvider,
} from 'pages/Setting/email-setting/EmailSetting/types/userToken.type';
import ReConnectCalendarModal from './ReConnectCalendarModal';
import Modal from 'components/Modal';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  onAdd?: (data?: any) => void;
  activityId?: number;
  activityTypeId?: number;
  saveAndDoneApiCall?: (
    saveDoneFormValues: MarkAsDoneFormFields
  ) => Promise<void>;
  setOpenCompleteModal?: Dispatch<SetStateAction<boolean>>;
  isSubmitLoading?: boolean;
  isMarkAsDoneWishScheduleFollowUp?: boolean;
}

const MarkAsDoneModal = (props: Props) => {
  const {
    closeModal,
    isOpen,
    onAdd,
    activityId,
    activityTypeId,
    saveAndDoneApiCall,
    setOpenCompleteModal,
    isSubmitLoading,
    isMarkAsDoneWishScheduleFollowUp = false,
  } = props;

  // ** states ** //
  const [defaultResult, setDefaultResult] = useState<{
    id: number;
    result: string;
  }>({ id: 0, result: '' });
  const [openModal, setOpenModal] = useState<{
    scheduleFollowUp: boolean;
    followUpDate?: Date;
  }>({
    scheduleFollowUp: false,
    followUpDate: undefined,
  });
  const [openReconnectModal, setOpenReconnectModal] = useState<{
    isOpen: boolean;
    provider: {
      value: TokenProvider | '';
      urlValue: AuthProviderConnectURLS | '';
    };
  }>({
    isOpen: false,
    provider: {
      urlValue: '',
      value: '',
    },
  });
  const [isFollowUpButtonClicked, setIsFollowUpButtonClick] =
    useState<boolean>(false);

  const [activityResultName, setActivityResultName] = useState<string>();

  // ** APIS **
  const [getActivityByIdAPI, { isLoading: isGetActivityLoading }] =
    useLazyGetActivityByIdQuery();

  // ** Custom hooks ** //
  const {
    formState: { errors },
    control,
    reset,
    watch,
    handleSubmit,
    setValue,
  } = useForm<MarkAsDoneFormFields>({
    resolver: yupResolver(markAsDoneActivitySchema),
    defaultValues: { result: '' },
  });

  // ** APIS **
  const [updateActivityByIdAPI, { isLoading }] = useUpdateActivityMutation();

  useEffect(() => {
    if (activityId) {
      getActivity(activityId);
    }
  }, [activityId]);

  const getActivity = async (id: number) => {
    const { data, error } = await getActivityByIdAPI({
      id,
      data: {
        query: {
          'include[activity_type][include][types_results][include][activity_result][select]':
            'id,result',
        },
      },
    });
    if (data && !error) {
      const activityResult = getDefaultActivityResult(data.activity_type);
      setDefaultResult(activityResult);
      setValue('result', activityResult?.id?.toString() || '');
    }
  };

  const markAsDoneApiCall = async (formValue: MarkAsDoneFormFields) => {
    if (saveAndDoneApiCall) {
      saveAndDoneApiCall(formValue);
    } else if (activityId) {
      const data = await updateActivityByIdAPI({
        id: activityId,
        data: {
          completed: true,
          result: activityResultName || 'other',
          memo: formValue.memo,
        },
      });
      return data;
    } else {
      return {};
    }
    return Promise.resolve(null);
  };

  const onSubmit = handleSubmit(async (formValue: MarkAsDoneFormFields) => {
    const data = await markAsDoneApiCall(formValue);
    if (data && 'data' in data) {
      close();
      onAdd?.(data.data);
    } else {
      //
    }
  });

  const followUpSubmit = handleSubmit(
    async (formValue: MarkAsDoneFormFields) => {
      setIsFollowUpButtonClick(true);
      await markAsDoneApiCall(formValue);
      setOpenModal({
        followUpDate: undefined,
        scheduleFollowUp: true,
      });
    }
  );

  const close = () => {
    reset();
    closeModal();
  };

  const closeCompleteModal = () => {
    if (saveAndDoneApiCall && setOpenCompleteModal) {
      setOpenCompleteModal(false);
    } else {
      close();
    }
  };

  const getMarkAsDoneAndFollowUpDropdownContent = () => {
    return (
      <div className="tippy__dropdown__ul">
        <div className="item">
          <div className="item__link">
            <div className="item__text">
              <button
                className=""
                type="button"
                onClick={() => followUpSubmit()}
              >
                Mark As Done and Follow-Up
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return isOpen ? (
    <Modal visible={isOpen}>
      <div
        className={`ip__Modal__Wrapper ip__Modal__Wrapper__new mark__as__done__modal ${
          !isOpen ? 'hidden' : ''
        }`}
      >
        <div
          className="ip__Modal__Overlay"
          onClick={() => closeCompleteModal()}
        />
        <div className="ip__Modal__ContentWrap w-[550px]">
          <div className="ip__Modal__Header">
            <h3 className="title">Mark As Done</h3>
            <Icon
              iconType="closeBtnFilled"
              onClick={() => closeCompleteModal()}
            />
          </div>

          <div className="ip__Modal__Body !pb-0 ip__FancyScroll relative">
            <form onSubmit={onSubmit}>
              <MarkAsDoneForm
                control={control}
                errors={errors}
                watch={watch}
                setActivityResultName={setActivityResultName}
                setValue={setValue}
                activityTypeId={activityTypeId}
                defaultResult={defaultResult}
                isGetActivityLoading={isGetActivityLoading}
              />
            </form>
          </div>

          <div className="ip__Modal__Footer">
            <Button
              className="bg-transparent min-w-[100px] rounded-[6px] text-[14px] font-biotif__SemiBold text-[#7467B7] py-[9px] px-[16px] border-[1px] border-[#7467B7] !mr-[12px] hover:bg-[#7467B7] hover:text-white"
              onClick={() => closeCompleteModal()}
            >
              Cancel
            </Button>

            <Button
              className="primary__Btn smaller min-w-[100px]"
              onClick={() => {
                onSubmit();
              }}
              isLoading={
                (isSubmitLoading || isLoading) && !isFollowUpButtonClicked
              }
            >
              Mark As Done
            </Button>

            {isMarkAsDoneWishScheduleFollowUp && (
              <Dropdown
                content={() => getMarkAsDoneAndFollowUpDropdownContent()}
                zIndex={10}
                interactive
                hideOnClick={false}
                customOnClick={() => setIsFollowUpButtonClick(false)}
              >
                <button
                  type="button"
                  className="i__Button primary__Btn relative w-[42px] h-[42px] p-0 !mr-0 sm:w-[36px] sm:h-[36px]"
                >
                  <span className="absolute top-[calc(50%_-_2px)] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[9px] h-[9px] border-l-[2px] border-l-[#ffffff] border-b-[2px] border-b-[#ffffff] rotate-[-45deg]" />
                </button>
              </Dropdown>
            )}
          </div>
        </div>
        {openModal.scheduleFollowUp &&
        activityId &&
        isMarkAsDoneWishScheduleFollowUp ? (
          <ScheduleFollowUpModal
            closeModal={() => {
              onAdd?.();
              closeModal();
            }}
            id={activityId}
            isOpen={openModal.scheduleFollowUp}
            followUpStartDate={openModal.followUpDate}
            onEdit={() => {
              onAdd?.();
              closeModal();
            }}
            setOpenReconnectModal={setOpenReconnectModal}
          />
        ) : (
          <></>
        )}

        {openReconnectModal.isOpen && isMarkAsDoneWishScheduleFollowUp ? (
          <ReConnectCalendarModal
            closeModal={() => {
              setOpenReconnectModal({
                isOpen: false,
                provider: {
                  urlValue: '',
                  value: '',
                },
              });
            }}
            isOpen={openReconnectModal.isOpen}
            provider={openReconnectModal.provider}
          />
        ) : null}
      </div>
    </Modal>
  ) : (
    <></>
  );
};

export default MarkAsDoneModal;
