
// ** Import Packages **
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// ** Redux **
import { setLoadTimeLines } from 'redux/slices/commonSlice';

// ** Components **
import Modal from 'components/Modal';
import DealLostForm from './DealLostForm';

// ** Types **
import {
  AddDealFormFieldsType,
  AddDealLostFormFieldsType,
  DealLostFieldType,
} from 'pages/Deal/types/deals.types';

// ** Custom Hooks **
import { getCurrentUser } from 'redux/slices/authSlice';

// ** Schema **
import {
  dealLostSchema,
  dealLostWithMemoSchema,
} from 'pages/Deal/validation-schema/deal.schema';

// ** Constant **
import { ModuleNames, POLYMORPHIC_MODELS } from 'constant/permissions.constant';
import { PRIVATE_NAVIGATION } from 'constant/navigation.constant';

// ** Other **
import generateDealFormData from 'pages/Deal/helper/deal.helper';
import { convertStringToBoolean, setUrlParams } from 'utils/util';
import { useLazyGetGeneralSettingQuery } from 'redux/api/generalSettingApi';
import { useUpdateDealStageMutation } from 'redux/api/dealStageHistoryApi';
import { useAddLeadMutation } from 'redux/api/leadApi';

interface Props {
  isOpen: boolean;
  onAdd?: () => void;
  closeModal: (data?: string) => void;
  displayField?: DealLostFieldType;
  id?: number | null;
  stageId?: number | null;
  formData?: AddDealFormFieldsType;
  setCustomIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
}
const AddLostReasonModal = (props: Props) => {
  const {
    closeModal,
    isOpen,
    onAdd,
    setCustomIsDirty,
    displayField,
    id,
    stageId,
    formData,
  } = props;

  // ** Hooks
  const { pathname } = useLocation();

  // ** Store
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);

  const navigate = useNavigate();
  // ** states **
  const [isMemoAllowed, setIsMemoAllowed] = useState(false);

  // ** APIS **
  const [getGeneralSetting] = useLazyGetGeneralSettingQuery();
  const [addLeadApi] = useAddLeadMutation();
  const [updateDealStageAPI, { isLoading }] = useUpdateDealStageMutation();

  useEffect(() => {
    checkIfMemoAllowed();
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
  } = useForm<AddDealLostFormFieldsType>({
    resolver: yupResolver(
      isMemoAllowed ? dealLostWithMemoSchema : dealLostSchema
    ),
  });


  const checkIfMemoAllowed = async () => {
    const { data, error } = await getGeneralSetting(
      {
        params: {
          'q[key]': 'is_memo_allowed_in_deal_lost',
          'q[model_name]': POLYMORPHIC_MODELS.USER,
          'q[model_record_id]': currentUser?.id,
          module: ModuleNames.DEAL,
        },
      },
      true
    );
    if (data && !error) {
      setIsMemoAllowed(convertStringToBoolean(data?.[0]?.value));
    }
  };

  const onSubmit = handleSubmit(async (value) => {
    if (setCustomIsDirty) {
      setCustomIsDirty(false);
    }
    let newId = id;
    const updatedDealValue = formData?.deal_value
      ?.match(/(\d+)(\.\d+)?/g)
      ?.join('');
    const updatedProbability = formData?.probability
      ?.match(/(\d+)(\.\d+)?/g)
      ?.join('');
    if (!id) {
      const DealFormData = generateDealFormData({
        ...formData,
        deal_value: updatedDealValue,
        probability: updatedProbability,
      });

      const data = await addLeadApi({
        data: DealFormData,
        params: { toast: false },
      });

      if ('data' in data && !('error' in data)) {
        newId = data?.data?.id;
      }
    }


    if (value.reason === 'other') {
      value.reason = value.otherReason as string;
    }
    if (value.otherReason || value.otherReason === '') {
      delete value.otherReason;
    }
    if (newId) {
      const DealFormData = new FormData();
      if (stageId) {
        DealFormData.append('stage_id', stageId?.toString());
      }
      DealFormData.append('is_deal', 'true');
      DealFormData.append('deal_id', newId.toString());

      const data = await updateDealStageAPI({ data: DealFormData });
      if (data) {
        dispatch(setLoadTimeLines({ timeline: true }));
        close('success');
        onAdd?.();
        if (pathname !== '/deals') {
          navigate(setUrlParams(PRIVATE_NAVIGATION.deals.detailPage, newId));
        }
      }
    };

  });

  const close = (data?: string) => {
    reset();
    closeModal(data);
  };

  return isOpen ? (
    <Modal
      title="Mark as Lost"
      visible={isOpen}
      onClose={() => close()}
      onCancel={() => close()}
      onSubmit={onSubmit}
      submitLoading={isLoading}
      width="476px"
      submitButtonText="Mark as Lost"
      submitButtonClass="delete__Btn"
    >
      <form onSubmit={onSubmit}>
        <DealLostForm
          errors={errors}
          register={register}
          displayField={displayField}
          control={control}
          reset={reset}
          setValue={setValue}
          watch={watch}
          isMemoAllowed={isMemoAllowed}
        />
        <p className="text-[16px] font-biotif__Regular text-light__TextColor">
          Manage lost reasons on{' '}
          <Link
            to={PRIVATE_NAVIGATION.settings.view}
            className="text-primaryColor underline"
          >
            Company settings
          </Link>
        </p>
      </form>
    </Modal>
  ) : (
    <></>
  );
};

export default AddLostReasonModal;
