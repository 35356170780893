import {
  PRIVATE_NAVIGATION,
  PUBLIC_NAVIGATION,
} from 'constant/navigation.constant';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getAuth } from 'redux/slices/authSlice';

interface Props {
  children: JSX.Element;
  pathName: string;
}

const TwoFactorProvider = ({ children, pathName }: Props) => {
  // ** Hooks **
  const { twoFactorEnable, twoFactorVerified } = useSelector(getAuth);
  if (
    pathName === PUBLIC_NAVIGATION.towFactorAuth &&
    twoFactorEnable &&
    twoFactorVerified
  ) {
    return <Navigate to={PRIVATE_NAVIGATION.dashboard.view} />;
  }
  return children;
};

export default TwoFactorProvider;
