import Image from 'components/Image';
import { checkAlertNotificationType } from '../helpers';
import { UserNotification } from '../types/notification.types';

interface Props {
  notification: UserNotification;
}

const ModelName = {
  leads: 'Lead',
  deals: 'Deal',
  activities: 'Activity',
  contacts: 'Contact',
  accounts: 'Account',
};

const ModuleRecordActionType = (props: Props) => {
  const { action, entityActionType } = useNotificationDataMining(
    props?.notification || {}
  );
  const creator = props?.notification?.notification?.creator;

  return (
    <div className="flex items-center">
      <div className="img__wrapper inline-flex w-[24px] h-[24px] [&>div>span]:!text-[13px] [&>div>span]:pt-[6px]">
        <Image
          imgPath={creator?.profile_image}
          first_name={creator?.first_name}
          last_name={creator?.last_name}
          height={24}
          width={24}
          serverPath
        />
      </div>
      <p className="text-[14px] text-black/50 font-biotif__Regular">
        <span className="text-primaryColor inline-block ml-[5px] capitalize">
          {creator?.full_name ||
            (creator?.first_name &&
              creator?.last_name &&
              `${creator?.first_name} ${creator?.last_name}`) ||
            ''}
        </span>{' '}
        {action}
        {entityActionType && ' '}
        {entityActionType}
      </p>
    </div>
  );
};

export default ModuleRecordActionType;

const useNotificationDataMining = (notification: UserNotification) => {
  const { timeline, changes, alertType, alertData } = notification?.notification
    ?.message || { timeline: undefined, changes: undefined };
  const {
    action,
    model_name,
    leads,
    model_record_id,
    contacts,
    accounts,
    related_activities,
    message,
  } = timeline || { action: undefined, model_name: undefined };
  const data = {
    ...(action ? { action: `${action} a` } : {}),
    entityActionType: !message?.fieldName?.includes('Created')
      ? message?.fieldName
      : '',
    name: (leads || contacts || accounts)?.name || related_activities?.topic,
    modelName: ModelName[model_name as keyof typeof ModelName],
    link: `/${model_name}/${model_record_id}`,
  };

  // ** activity notification message **
  if (model_name === 'activities' && data.action === 'Created a') {
    data.action = 'Schedule an';
    data.entityActionType = message?.newValue?.value?.toLowerCase();
    data.name = timeline?.related_activities?.activity_lead?.name || '';
  }

  if (data.action === 'Created a') {
    data.entityActionType = data?.modelName;
  }

  if (notification?.notification?.message.reaction) {
    // ** comment notification message **
    // if (notification?.notification?.message?.changes?.fieldName === 'comment_mention') {
    //     data.action = 'Comment a';
    // }

    // ** reaction notification message **
    data.action = 'Reacted on';
  }

  // ** comment notification message **
  if (notification?.notification?.message.comment) {
    data.action = 'Commented on';
  }
  if (
    changes instanceof Array &&
    changes[0]?.fieldName?.indexOf('Tag') !== -1
  ) {
    data.entityActionType = 'Tag';
  }

  if (
    notification?.notification?.message?.timeline?.relation_model_name ===
    'notes' &&
    notification?.notification?.message?.timeline?.note
  ) {
    // ** action on note timeline **
    data.entityActionType = 'Note';
  }

  // ** document created notification message **
  if (
    timeline?.documents?.length > 0 &&
    !notification?.notification?.message.reaction &&
    !notification?.notification?.message.comment
  ) {
    data.action = 'Created a';
  }

  if (
    changes instanceof Array &&
    changes[0]?.fieldName?.indexOf('Document') !== -1
  ) {
    data.entityActionType = 'Document';
  }

  if (
    changes instanceof Array &&
    changes[0]?.fieldName?.indexOf('notes') !== -1 &&
    notification?.notification?.message?.timeline?.relation_model_name ===
    'notes'
  ) {
    data.entityActionType = 'Note';
  }

  // ** note mentioned notification message **
  if (
    changes?.fieldName === 'comment_mention' ||
    (timeline?.note && changes?.fieldName === 'note_user')
  ) {
    data.action = 'Mentioned on';
    data.modelName =
      (changes?.fieldName === 'comment_mention' && 'Comment') ||
      (changes?.fieldName === 'note_user' && 'Note') ||
      '';
  }

  if (data.action === 'Deleted a') {
    // data.entityActionType = '';
    if (
      notification?.notification?.message?.changes[0] &&
      notification?.notification?.message?.changes[0]?.newValue?.value
    ) {
      data.name =
        notification?.notification?.message?.changes[0]?.newValue?.value;
      data.entityActionType = data?.modelName;
    }
  }

  if (data.action === 'Restored a') {
    data.entityActionType = '';
    if (
      notification?.notification?.message?.changes[0] &&
      notification?.notification?.message?.changes[0]?.newValue?.value
    ) {
      data.name =
        notification?.notification?.message?.changes[0]?.newValue?.value;
      data.entityActionType = data?.modelName;
    }
  }

  if (alertType) {
    checkAlertNotificationType(
      { alertType, alertData },
      notification?.notification?.creator,
      data
    );
  }

  return data;
};
