import { useSelector } from 'react-redux';
import { getNotificationsData } from 'redux/slices/notificationSlice';
import RenderItemsHandler from './RenderItemsHandler';
import NoDataFound from 'components/EntityDetails/Timeline/components/NoDataFound';

type Props = {
  visible: number;
};

const NotificationList = (props: Props) => {
  const {
    visible,
  } = props;

  const notification = useSelector(getNotificationsData);
  return (
    <>
      {notification?.length !== undefined &&
        Number(notification?.length) > 0 ? (
        notification?.slice(0, visible).map((item, index) => {
          return (
            <div
              key={`${item?.notification.id}_notifications_${item.created_at}_${item?.user_id}}`}
              className="notification__item__wrapper pb-[10px] border-b border-b-[#CCCCCC]/20"
            >
              <RenderItemsHandler notification={item} indexNo={index} />
            </div>
          );
        })
      ) : (
        <NoDataFound />
      )}
    </>
  );
};

export default NotificationList;
