// ** import packages **
import { format as Format } from 'date-fns-tz';
import { useSelector } from 'react-redux';

// ** redux **
import { getCurrentUserDateFormat } from 'redux/slices/authSlice';

interface Props {
  date: string | Date;
  format?: string;
}

export const DateFormat = (props: Props) => {
  const { date, format } = props;

  // ** hooks **
  const dateFormat: string =
    format || useSelector(getCurrentUserDateFormat) || 'MM/dd/yyyy';

  const renderDate = () => {
    if (dateFormat) {
      return Format(new Date(date), dateFormat);
    }
    return null;
  };

  const renderDateFunction = () => {
    if (!Number.isNaN(new Date(date).getDate())) {
      return renderDate();
    }
    return date;
  };

  return <>{date && renderDateFunction()}</>;
};

export default DateFormat;
