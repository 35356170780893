// ** Components ** //
import EditActivityMailModal from 'components/Modal/EditActivityMailModal';

interface Props {
    isOpen: boolean;
    discardActivity: () => void;
    closeModal: () => void;
    sendInvitationMail: () => void;
    saveActivityLoading: boolean;
    isLoading: boolean;
}

const SendInvitationMailModal = ({
    isOpen,
    closeModal,
    discardActivity,
    sendInvitationMail,
    saveActivityLoading,
    isLoading,
}: Props) => {

    return isOpen ? (
        <EditActivityMailModal
            title="Discard"
            visible={isOpen}
            onClose={closeModal}
            onCancel={closeModal}
            onSubmit={discardActivity}
            onSendMailButtonClick={sendInvitationMail}
            width="800px"
            submitButtonText="Don't Send"
            sendMailButtonText="Send"
            saveActivityLoading={saveActivityLoading}
            isLoading={isLoading}
        >
            <p className="confirmation__title">
                Would you like to send invitation emails to guests?
            </p>
        </EditActivityMailModal>
    ) : (
        <></>
    );
};

export default SendInvitationMailModal;
