import { addMinutes } from 'date-fns';
import { useDispatch } from 'react-redux';
import { setCSRFExpireTime, setCSRFToken } from 'redux/slices/commonSlice';
import { useGetCSRFTokenAPI } from 'services/general.service';

export const useGetAndSetCSRFtoken = () => {
  const { getCSRFTokenAPI,isLoading:isCSRFTokenFetching } = useGetCSRFTokenAPI();
  const dispatch = useDispatch();
  const getAndSetCSRFtoken = async () => {
    const data = await getCSRFTokenAPI();
    const csrfToken = data?.data?.csrfToken || '';
    dispatch(setCSRFToken({ csrfToken }));
    dispatch(setCSRFExpireTime({ expireDate: addMinutes(new Date(),53)?.toISOString() }));
  };

  return { getAndSetCSRFtoken,isCSRFTokenFetching };
};
