// ** Import Packages **
import { AxiosRequestConfig } from 'axios';

// ** API **
import baseQueryApi from './baseQueryApi';

const GENERAL_SETTING_API_BASE_PATH = '/general-settings';

export const generalSettingApi = baseQueryApi.injectEndpoints({
  endpoints: (builder) => ({
    getGeneralSetting: builder.query<any, AxiosRequestConfig<any>>({
      query: ({ data, ...rest }) => {
        return {
          url: `${GENERAL_SETTING_API_BASE_PATH}`,
          method: 'GET',
          data,
          extraOptions: { ...rest },
        };
      },
      providesTags: () => {
        return [{ type: 'GENERAL_SETTING', id: 'LIST' }];
      },
    }),
    addUpdateGeneralSetting: builder.mutation<any, AxiosRequestConfig<any>>({
      query: ({ data, ...rest }: AxiosRequestConfig<any>) => {
        return {
          url: `${GENERAL_SETTING_API_BASE_PATH}`,
          method: 'POST',
          data,
          extraOptions: { ...rest },
        };
      },
      invalidatesTags: (_result, error) => {
        return error ? [] : [{ type: 'GENERAL_SETTING', id: 'LIST' }];
      },
    }),
  }),
});

export const {
  useLazyGetGeneralSettingQuery,
  useAddUpdateGeneralSettingMutation,
} = generalSettingApi;
