/* eslint-disable no-underscore-dangle */

declare global {
  interface Window {
    __REACT_DEVTOOLS_GLOBAL_HOOK__?: Record<
      string,
      Map<any, any> | (() => any)
    >;
  }
}

export function disableDevTools() {
  if (!window.__REACT_DEVTOOLS_GLOBAL_HOOK__) return;

  Object.keys(window.__REACT_DEVTOOLS_GLOBAL_HOOK__).forEach((k) => {
    let replacement: undefined | Map<unknown, unknown>;

    if (k === 'renderers') {
      replacement = new Map();
    }
    // eslint-disable-next-line
    window.__REACT_DEVTOOLS_GLOBAL_HOOK__![k] =
      replacement || (() => undefined);
  });
}
