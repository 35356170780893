// ** Import Packages **
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// ** Redux **
import { RootState } from 'redux/store';

//  ** Constants **
import {
  notificationStatus,
  UserNotification,
} from 'pages/Dashboard/components/Notification/types/notification.types';
import { getNotificationsApi } from 'redux/api/notification/notificationApi';
import {
  notificationHelper,
  removeNotification,
} from 'redux/api/helper/notification.helper';
import { PURGE } from 'redux-persist';

export interface NotificationReduxState {
  notifications: UserNotification[] | null;
  notificationCounts: number;
  offset: number;
  hasMore: boolean;
  clear: boolean;
  setting: {
    summary_notification_time: string | number | Date | null;
    daily_notification_time: string | number | Date | null;
  };
  params: { [key: string]: number | string | object | boolean };
  pollingInterval: number;
}

const initialState: NotificationReduxState = {
  notifications: null,
  notificationCounts: 0,
  offset: 0,
  hasMore: true,
  setting: {
    summary_notification_time: null,
    daily_notification_time: null,
  },
  clear: false,
  params: {
    'q[status]': notificationStatus.UNREAD,
    'include[notification][select]':
      'id,message,title,model_record_id,model_name',
    // 'include[notification][include][creator]': 'all',
    offset: 0,
    sort: '-created_at',
  },
  pollingInterval: 0,
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications(
      state: NotificationReduxState,
      action: PayloadAction<{ count: number; data: UserNotification[] | null }>
    ) {
      const { count, data } = action.payload;
      state.notifications = data;
      state.notificationCounts = count;
    },

    setNotificationsCount(
      state: NotificationReduxState,
      action: PayloadAction<number>
    ) {
      state.notificationCounts = action.payload;
    },

    setRemoveNotification(
      state: NotificationReduxState,
      action: PayloadAction<{
        index: number[];
        status: notificationStatus;
      }>
    ) {
      removeNotification(state, action);
    },

    setNotificationOffset(
      state: NotificationReduxState,
      action: PayloadAction<{ offset: number }>
    ) {
      state.offset = action.payload.offset;
    },

    setNotificationSetting(
      state: NotificationReduxState,
      action: PayloadAction<
        | { summary_notification_time: string | Date | null }
        | { daily_notification_time: string | Date | null }
      >
    ) {
      state.setting = { ...state.setting, ...action.payload };
    },

    setNotificationParams(
      state: NotificationReduxState,
      action: PayloadAction<{ [key: string]: string | number | object }>
    ) {
      state.params = action.payload;
      state.hasMore = true;
      state.offset = 0;
    },

    setNotificationClear(
      state: NotificationReduxState,
      action: PayloadAction<boolean>
    ) {
      state.clear = action.payload;
    },

    setNotificationInterval(
      state: NotificationReduxState,
      action: PayloadAction<number>
    ) {
      state.pollingInterval = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
    builder.addMatcher(
      getNotificationsApi.endpoints.getNotificationsAPI.matchFulfilled,
      (state, action) => {
        notificationHelper(state, action);
        return state;
      }
    );
  },
});

export const { reducer } = slice;

export const {
  setNotifications,
  setNotificationsCount,
  setRemoveNotification,
  setNotificationSetting,
  setNotificationParams,
  setNotificationOffset,
  setNotificationClear,
  setNotificationInterval,
} = slice.actions;

export const getNotifications = (state: RootState) => {
  const { notifications, notificationCounts } = state.notification;
  return { notifications, notificationCounts };
};

export const getNotificationCount = (state: RootState) =>
  state.notification.notificationCounts;

export const getNotificationsData = (state: RootState) =>
  state.notification.notifications;

export const getNotificationOffset = (state: RootState) =>
  state.notification.offset;

export const getNotificationsSetting = (state: RootState) =>
  state.notification.setting;

export const getNotificationParams = (state: RootState) =>
  state.notification.params;

export const getNotificationClear = (state: RootState) =>
  state.notification.clear;

export const getHasMoreNotification = (state: RootState) =>
  state.notification.hasMore;

export const getNotificationTimeInterval = (state: RootState) =>
  state.notification.pollingInterval;

export default slice;
