// ** Import Packages **
import { useState } from 'react';
import { useDispatch } from 'react-redux';

// ** Redux **
import { setNotificationInterval } from 'redux/slices/notificationSlice';
import {
  setAuthenticated,
  setCurrentStep,
  setOrganizationUUID,
  setPermissions,
  setTwoFactor,
  setUserData,
} from 'redux/slices/authSlice';

// ** Types **
import {
  LoginFormFields,
  loginMethod,
  LoginWithAppleSchema,
} from './types/login.types';

// ** Services **

import { useGetReportsUser } from 'pages/Setting/user-setting/User/hooks/useUserService';

import {
  useAppleLoginAPI,
  useGoogleLoginAPI,
  useLoginAPI,
} from '../services/auth.service';
import { convertBtoA } from 'utils/util';

// ** Constant **
import { LOGIN_STEP, NOTIFICATION_INTERVAL_TIME, USER_STATUS } from 'constant';
import { useLazyGetLoggedUserQuery } from 'redux/api/userApi';

import { useNavigate } from 'react-router-dom';
import { PUBLIC_NAVIGATION } from 'constant/navigation.constant';
import { useGetStreamData } from 'pages/Streams/hooks/stream.service';
import {
  setStreamLogObjFilter,
  setStreamList,
} from 'redux/slices/stream.Slice';
import _ from 'lodash';
import useToast from 'hooks/useToast';

interface Props {
  setSocialLogin?: React.Dispatch<React.SetStateAction<boolean>>;
}

const useLoginService = ({ setSocialLogin }: Props) => {
  // ** hooks **
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // ** Custom Hooks **
  const { loginAPI, isLoading: simpleLoginLoading } = useLoginAPI();
  const { googleLoginAPI, isLoading: googleLoginLoading } = useGoogleLoginAPI();
  const { appleLoginAPI, isLoading: appleLoginLoading } = useAppleLoginAPI();
  const [getLoggedInUserAPI, { isLoading: loggedInUserLoading }] =
    useLazyGetLoggedUserQuery();

  const { userOrDescendantUserOptions } = useGetReportsUser();
  const { setToastMessage } = useToast();

  // ** states **
  const [currentLoginMethod, setCurrentLoginMethod] = useState<loginMethod>(
    loginMethod.LOGIN
  );
  const { getStreamAPI } = useGetStreamData();
  // login loading manage
  const formLoading = {
    simpleLoginLoader:
      currentLoginMethod === loginMethod.LOGIN &&
      (simpleLoginLoading || loggedInUserLoading),
    googleLoginLoader:
      currentLoginMethod === loginMethod.GOOGLE_LOGIN &&
      (googleLoginLoading || loggedInUserLoading),
    appleLoginLoader:
      currentLoginMethod === loginMethod.APPLE_LOGIN &&
      (appleLoginLoading || loggedInUserLoading),
  };

  // *** general login ***
  const loginUser = async (loginData: LoginFormFields) => {
    setCurrentLoginMethod(loginMethod.LOGIN);
    const formData = { ...loginData };
    delete formData.remember;

    const { data, error } = await loginAPI(formData);

    if (!error && data) {
      setUserRememberToLocal(loginData);
      loginVerifyHandler(data);
    }
    if (data?.statusCode === 5002) {
      navigate(`${PUBLIC_NAVIGATION.setPassword}?token=${data?.token}`, {
        state: { expiredChangesPass: true },
      });
    }
  };

  // *** login with google account ***
  const loginWithGoogle = async (idToken: string) => {
    setCurrentLoginMethod(loginMethod.GOOGLE_LOGIN);
    if (setSocialLogin) {
      setSocialLogin(true);
    }
    const { data, error } = await googleLoginAPI({
      idToken,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    if (data && !error) {
      loginVerifyHandler(data);
    } else if (data?.statusCode === 5002) {
      navigate(`/set-password?token=${data?.token}`);
    } else {
      //
    }
  };

  // *** login with apple account ***
  const loginWithApple = async (response: LoginWithAppleSchema) => {
    setCurrentLoginMethod(loginMethod.APPLE_LOGIN);
    if (setSocialLogin) {
      setSocialLogin(true);
    }
    const { data, error } = await appleLoginAPI({
      ...response,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    if (data && !error) {
      loginVerifyHandler(data);
    } else if (data?.statusCode === 5002) {
      navigate(`/set-password?token=${data?.token}`);
    } else {
      //
    }
  };

  // *** handle to login response data to verify user and decide to next step to verification ***
  const loginVerifyHandler = (data: any) => {
    const {
      organizations = [],
      two_factor_enabled,
      two_factor_verified,
      org_twoFA_status,
    } = data;

    dispatch(setAuthenticated({ isAuthenticated: true }));

    const twoFactorEnabled =
      (two_factor_enabled || org_twoFA_status) && two_factor_verified === false;
    if (!organizations.length) {
      dispatch(setCurrentStep({ currentStep: LOGIN_STEP.COMPANY_DETAILS }));
    } else if (
      !organizations?.filter(
        (org: { user_status: USER_STATUS }) =>
          org.user_status === USER_STATUS.ACTIVE
      )?.length
    ) {
      setToastMessage({
        message:'Your account has been deactivate/archive. Please contact admin for re-activate your account',
        type: 'error',
      });
    } else if (organizations.length === 1) {
      const organizationId = organizations[0].uuid;
      dispatch(setOrganizationUUID(organizationId));
      if (twoFactorEnabled) {
        dispatch(setTwoFactor({ twoFactorEnable: true }));
      } else {
        isVerified();
      }
    } else {
      dispatch(setCurrentStep({ organizationList: organizations }));
      if (twoFactorEnabled) {
        dispatch(setTwoFactor({ twoFactorEnable: true }));
      } else {
        dispatch(setCurrentStep({ currentStep: LOGIN_STEP.ORGANIZATION_FORM }));
      }
    }
  };

  // *** mark to remember user data store ***
  const setUserRememberToLocal = (loginData: LoginFormFields) => {
    if (loginData.remember) {
      localStorage.setItem(
        'remember-me',
        JSON.stringify({
          ...loginData,
          password: convertBtoA(loginData.password),
        })
      );
    } else {
      localStorage.removeItem('remember-me');
    }
  };

  // *** set organization id to user verify ***
  const addOrganization = (organizationId: string) => {
    dispatch(setOrganizationUUID(organizationId));
    isVerified();
  };

  // *** logged user verify to data ***
  const isVerified = async () => {
    const { data, error } = await getLoggedInUserAPI({});
    if (!error && data) {
      const { two_factor_enabled, two_factor_verified } = data;

      const { data: streamFilleterData } = await getStreamAPI({});
      if (streamFilleterData) {
        const userFilleter =
          streamFilleterData?.rows?.[0]?.save_filter?.userFilter;
        const itemFilleter = streamFilleterData?.rows?.map((filleter: any) => {
          return {
            [`${filleter.id}`]: filleter?.save_filter?.fitter,
          };
        });

        const resultObject = {};

        _.forEach(itemFilleter, (element) => {
          _.merge(resultObject, element);
        });

        if (userFilleter) {
          dispatch(setStreamList({ ...userFilleter }));
        }
        if (itemFilleter) {
          dispatch(setStreamLogObjFilter({ all_item: resultObject }));
        }
      }

      if (data.user?.user_organizations?.length === 0) {
        dispatch(setCurrentStep({ currentStep: LOGIN_STEP.COMPANY_DETAILS }));
      } else if (data.user && !data.user.verified) {
        dispatch(setCurrentStep({ currentStep: LOGIN_STEP.VERIFY_EMAIL }));
      } else if (two_factor_enabled && !two_factor_verified) {
        dispatch(setTwoFactor({ twoFactorEnable: true }));
      } else if (
        !two_factor_enabled ||
        (two_factor_enabled && two_factor_verified)
      ) {
        const { user, permissions } = data;
        dispatch(setUserData({ user }));
        dispatch(setPermissions(permissions));
        await userOrDescendantUserOptions(user);
        dispatch(setNotificationInterval(NOTIFICATION_INTERVAL_TIME));
        dispatch(setCurrentStep(undefined));
      } else {
        //
      }
    } else if (data && data?.two_factor_enabled && !data?.two_factor_verified) {
      dispatch(setTwoFactor({ twoFactorEnable: true }));
    } else {
      dispatch(setCurrentStep({ currentStep: LOGIN_STEP.COMPANY_DETAILS }));
    }
  };

  return {
    loginUser,
    loginWithGoogle,
    loginWithApple,
    isVerified,
    addOrganization,
    loggedInUserLoading,
    formLoading,
  };
};

export default useLoginService;
