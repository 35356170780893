import Button from 'components/Button';
import FormField from 'components/FormField';
import { useForm } from 'react-hook-form';
import { checkInputIsNumber } from 'utils/util';

import { use2FACodeVerifyAPI } from '../services/auth.service';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAuth,
  getCurrentStep,
  setCurrentStep,
  setTwoFactor,
  setTwoFactorVerified,
} from 'redux/slices/authSlice';
import useLoginService from '../Login/useLoginService';
import {
  PRIVATE_NAVIGATION,
  PUBLIC_NAVIGATION,
} from 'constant/navigation.constant';
import { useNavigate } from 'react-router-dom';
import { LOGIN_STEP } from 'constant';
import { useLazyGetTowFactorQRQuery } from 'redux/api/authApi';
import { useEffect } from 'react';
import useAuth from 'hooks/useAuth';
import { isAdministrator } from 'utils/is';

interface Props {
  isVerifyEnable?: boolean;
  closeModal?: () => void;
}

function TowFactorVerifyCard({ isVerifyEnable, closeModal }: Props) {
  // ** hooks **
  const dispatch = useDispatch();
  const organizationOptions = useSelector(getCurrentStep);
  const navigate = useNavigate();

  // ** custom hooks **
  const { logout } = useAuth();
  const { organizationUUID, isAuthenticated, user } = useSelector(getAuth);
  const { verify2FACodeAPI } = use2FACodeVerifyAPI();
  const { isVerified } = useLoginService({});
  const [getTowFactorQR, { currentData }] = useLazyGetTowFactorQRQuery();

  useEffect(() => {
    if (isAuthenticated) {
      getTowFactorQR({});
    }
  }, []);

  const formMethods = useForm<{ code: string }>({
    defaultValues: { code: '' },
  });
  const {
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
    control,
    register,
    watch,
  } = formMethods;

  const onSubmit = handleSubmit(async (formVal: { code: string }) => {
    const formData = {
      ...formVal,
      secret: currentData?.secret,
      ...(isVerifyEnable ? { twoFa_status: true } : {}),
    };
    const { data: verify, error: verifyError } = await verify2FACodeAPI(
      formData
    );
    if (!verifyError && verify.verified) {
      dispatch(setTwoFactor({ twoFactorEnable: true }));
      dispatch(setTwoFactorVerified({ twoFactorVerified: true }));
      if (isAuthenticated && !!user?.verified && organizationUUID) {
        navigate(PRIVATE_NAVIGATION.dashboard.view);
      } else if (
        !organizationUUID &&
        organizationOptions &&
        organizationOptions?.organizationList?.length > 0
      ) {
        dispatch(setCurrentStep({ currentStep: LOGIN_STEP.ORGANIZATION_FORM }));
        navigate(PUBLIC_NAVIGATION.login);
      } else {
        isVerified();
      }
    } else {
      setError('code', { message: "Verification Code doesn't match...!" });
    }
  });
  return (
    <>
      <h3 className="text-[26px] font-biotif__Medium text-center text-black mb-[10px]">
        Two Factor Authentication
      </h3>
      <form onSubmit={onSubmit}>
        {!isAdministrator() && (
          <h3 className="text-[16px] font-biotif__Medium text-black/50 mb-[20px]">
            Admin turned 2FA required for the organization.
          </h3>
        )}
        {isVerifyEnable && (
          <>
            <h3 className="text-[14px] font-biotif__Medium text-black/50 mb-[20px]">
              <span className="bold">1.</span> Install an authenticator app on
              or mobile device.
            </h3>
            <h3 className="text-[14px] font-biotif__Medium text-black/50 mb-[5px]">
              <span className="bold">2.</span> Scan the following QR code in
              your authenticator app:
            </h3>
          </>
        )}
        {currentData?.QRCode && (
          <div className="border border-[#CCCCCC]/80 rounded-[20px] p-[15px] flex justify-center items-center mb-[20px]">
            <img
              className="inline-block w-[250px]"
              src={currentData?.QRCode}
              alt=""
            />
          </div>
        )}
        {isVerifyEnable && (
          <>
            <h3 className="text-[14px] font-biotif__Medium text-black/50">
              <span className="bold">3.</span> Enter the code from your
              authenticator app bellow:
            </h3>
          </>
        )}
        <FormField
          type="text"
          name="code"
          className="ip__input mb-5 mt-5"
          placeholder="Enter Code"
          onChange={(e) => {
            if (errors.code) {
              clearErrors();
            }
            return (
              !Number.isNaN(Number(e.target.value)) &&
              setValue('code', e.target.value)
            );
          }}
          onKeyDown={checkInputIsNumber}
          fieldLimit={6}
          control={control}
          error={errors.code}
          inputMode="numeric"
          register={register}
        />
        <div className="flex justify-end">
          <Button
            className="bg-transparent w-[120px] rounded-[6px] text-[14px] font-biotif__SemiBold text-[#7467B7] py-[9px] px-[16px] border-[1px] border-[#7467B7] !mr-[12px] hover:bg-[#7467B7] hover:text-white"
            type="button"
            onClick={closeModal || logout}
          >
            {isVerifyEnable ? 'Cancel' : 'Logout'}
          </Button>
          <Button
            className="bg-[#7467B7] w-[120px] rounded-[6px] text-[14px] font-biotif__SemiBold text-white py-[9px] px-[16px] hover:bg-[#6054A0]"
            type="submit"
            isDisabled={watch('code').length !== 6}
          >
            Verify
          </Button>
        </div>
      </form>
    </>
  );
}

export default TowFactorVerifyCard;
