// ** Redux ** //
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

// ** Components **

// ** types ** //
import { PURGE } from 'redux-persist';

const initialState: { filterData: string[]; userIds: string[] } = {
  filterData: [],
  userIds: [],
};

const slice = createSlice({
  name: 'audit',
  initialState,
  reducers: {
    setAuditLogFilterData(
      state: { filterData: string[]; userIds: string[] },
      action: PayloadAction<{ filterData: string[]; userIds: string[] }>
    ) {
      state.filterData = action.payload.filterData;
      state.userIds = action.payload.userIds;
    },
  },
  extraReducers(builder) {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const { reducer } = slice;
export const { setAuditLogFilterData } = slice.actions;

export const getAuditLogFilter = (state: RootState) => state.audit;
