export const smileys: readonly { [key: string]: string }[] = Object.freeze([
  { content: '&#128512;', title: 'Grinning face' },
  { content: '&#128513;', title: 'Grinning face with smiling eyes' },
  { content: '&#128514;', title: 'Face with tears of joy' },
  { content: '&#128515;', title: 'Smiling face with open mouth' },
  {
    content: '&#128516;',
    title: 'Smiling face with open mouth and smiling eyes',
  },
  {
    content: '&#128517;',
    title: 'Smiling face with open mouth and cold sweat',
  },
  {
    content: '&#128518;',
    title: 'Smiling face with open mouth and tightly-closed eyes',
  },
  { content: '&#128519;', title: 'Smiling face with halo' },
  { content: '&#128520;', title: 'Smiling face with horns' },
  { content: '&#128521;', title: 'Winking face' },
  { content: '&#128522;', title: 'Smiling face with smiling eyes' },
  { content: '&#128523;', title: 'Face savouring delicious food' },
  { content: '&#128524;', title: 'Relieved face' },
  { content: '&#128525;', title: 'Smiling face with heart-shaped eyes' },
  { content: '&#128526;', title: 'Smiling face with sunglasses' },
  { content: '&#128527;', title: 'Smirking face"' },
  { content: '&#128528;', title: 'Neutral face' },
  { content: '&#128529;', title: 'Expressionless face' },
  { content: '&#128530;', title: 'Unamused face' },
  { content: '&#128531;', title: 'Face with cold sweat' },
  { content: '&#128532;', title: 'Pensive face' },
  { content: '&#128533;', title: 'Confused face' },
  { content: '&#128534;', title: 'Confounded face' },
  { content: '&#128535;', title: 'Kissing face' },
  { content: '&#128536;', title: 'Face throwing a kiss' },
  { content: '&#128538;', title: 'Kissing face with smiling eyes' },
  { content: '&#128539;', title: 'Face with stuck-out tongue' },
  { content: '&#128540;', title: 'Face with stuck-out tongue and winking eye' },
  {
    content: '&#128541;',
    title: 'Face with stuck-out tongue and tightly-closed eyes',
  },
  { content: '&#128542;', title: 'Disappointed face' },
  { content: '&#128543;', title: 'Worried face' },
  { content: '&#128544;', title: 'Angry face' },
  { content: '&#128545;', title: 'Pouting face' },
  { content: '&#128546;', title: 'Crying face' },
  { content: '&#128547;', title: 'Persevering face' },
  { content: '&#128548;', title: 'Face with look of triumph' },
  { content: '&#128549;', title: 'Disappointed but relieved face' },
  { content: '&#128550;', title: 'Frowning face with open mouth' },
  { content: '&#128551;', title: 'Anguished face' },
  { content: '&#128552;', title: 'Fearful face' },
  { content: '&#128553;', title: 'Weary face' },
  { content: '&#128554;', title: 'Sleepy face' },
  { content: '&#128555;', title: 'Tired face' },
  { content: '&#128556;', title: 'Grimacing face' },
  { content: '&#128557;', title: 'Loudly crying face' },
  { content: '&#128558;', title: 'Face with open mouth' },
  { content: '&#128559;', title: 'Hushed face' },
  { content: '&#128560;', title: 'Face with open mouth and cold sweat' },
  { content: '&#128561;', title: 'Face screaming in fear' },
  { content: '&#128562;', title: 'Astonished face' },
  { content: '&#128563;', title: 'Flushed face' },
  { content: '&#128564;', title: 'Sleeping face' },
  { content: '&#128565;', title: 'char_block' },
]);
