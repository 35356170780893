import { GENERAL_SETTING_VALID_KEYS } from 'constant';
import { POLYMORPHIC_MODELS } from 'constant/permissions.constant';
import { useLazyGetGeneralSettingQuery } from 'redux/api/generalSettingApi';
import { getCurrentUser } from 'redux/slices/authSlice';
import { GeneralSetting } from '../../service/types/generalSettings.types';
import { Dispatch, SetStateAction } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { setViewDiscardPromptStatus } from 'redux/slices/commonSlice';

interface IUseGetViewDiscardPromptSetting {
  setIsPromptEnable?: Dispatch<SetStateAction<boolean>>;
}
export const useGetViewDiscardPromptSetting = (
  props: IUseGetViewDiscardPromptSetting
) => {
  const { setIsPromptEnable } = props;
  const [getGeneralSetting] = useLazyGetGeneralSettingQuery();
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();

  const getViewDiscardPromptSetting = async () => {
    const { data, error } = await getGeneralSetting(
      {
        params: {
          'q[key]': GENERAL_SETTING_VALID_KEYS.is_view_discard_prompt_required,
          'q[model_name]': POLYMORPHIC_MODELS.USER,
          'q[model_record_id]': currentUser?.id,
          module: 'organizations',
        },
      },
      true
    );
    if (data && !error) {
      const userViewPromptData = data?.find(
        (d: GeneralSetting) =>
          d.key === GENERAL_SETTING_VALID_KEYS.is_view_discard_prompt_required
      );
      if (userViewPromptData) {
        setIsPromptEnable?.(userViewPromptData?.value === 'true');
        dispatch(
          setViewDiscardPromptStatus({
            status: userViewPromptData?.value === 'true',
          })
        );
      }
    }
  };

  return {
    getViewDiscardPromptSetting,
  };
};
