import Tippy from '@tippyjs/react';
import DateTimeSince from 'components/DateFormat/DateTimeSince';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getNotificationParams } from 'redux/slices/notificationSlice';
import {
  notificationStatus,
  UserNotification,
} from '../types/notification.types';
import ModuleRecordActionType from './NotificationTypes';
import RenderMessage from './RenderMessage';

interface Props {
  indexNo: number[];
  notification: UserNotification;
  showMoreHandler?: () => void;
  showMore?: boolean;
  isLastIndex?: boolean;
  markAsRead: (id: number[] | 'all', status: boolean, index?: number[]) => void;
  isParent?: boolean;
}

const NotificationItems = ({
  indexNo,
  notification,
  showMoreHandler,
  showMore,
  isLastIndex = false,
  markAsRead,
  isParent = false,
}: Props) => {
  const params = useSelector(getNotificationParams);
  const inputRef = useRef<HTMLInputElement>(null);
  const { leads, contacts, accounts, related_activities } =
    notification?.notification?.message?.timeline || {};
  const [status, setStatus] = useState(
    params['q[status]'] === notificationStatus.UNREAD
  );
  useEffect(() => {
    setStatus(params['q[status]'] === notificationStatus.UNREAD);
  }, [notification, params]);

  const readNotification = (
    e:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.ChangeEvent<HTMLInputElement>,
    notificationId: number
  ) => {
    const isElemInsideGroup = document.getElementById(
      `${notificationId}_groupNotification`
    );
    const isElemInsideShowLess = document.getElementById(
      `show_less_${notificationId}`
    );
    if (
      !isElemInsideGroup?.contains(e.target as Node) &&
      !isElemInsideShowLess?.contains(e.target as Node)
    ) {
      let argStatus = !inputRef.current?.checked;
      if (e.target === inputRef.current) {
        argStatus = inputRef.current?.checked;
      }
      markAsRead(
        [+notificationId],
        params['q[status]'] === notificationStatus.READ
          ? argStatus
          : !argStatus,
        indexNo
      );
      if (inputRef.current) {
        setStatus(!status);
        inputRef.current.checked =
          e.target === inputRef.current
            ? inputRef.current?.checked
            : !inputRef.current?.checked;
      }
    }
  };

  const outerDivClassName = () => {
    let lastIndexText = '';
    let showMoreText;
    let hiddenText = '';
    if (isLastIndex) {
      lastIndexText = 'last__item';
    }
    if (!isParent && showMore) {
      showMoreText = 'block';
    } else if (!isParent && !showMore) {
      showMoreText = 'hidden';
    } else {
      showMoreText = '';
    }
    if (!showMore && isParent) {
      hiddenText = 'before:hidden';
    }
    return `notification__item relative pt-[15px] pb-[6px] px-[15px] pr-[44px] flex flex-wrap sm:pl-[35px] ${lastIndexText} ${showMoreText} ${hiddenText}`;
  };

  return (
    <>
      <div
        onClick={(e) =>
          inputRef.current &&
          e.target !== inputRef.current &&
          readNotification(e, notification.notification_id)
        }
        className={outerDivClassName()}
      >
        <Tippy
          key={`${notification.notification_id}_tooltip`}
          zIndex={7}
          content={`Mark as ${status ? 'Read' : 'Unread'}`}
        >
          <div
            key={`${notification.notification_id}_input_div`}
            className="custom__checkbox cursor-pointer flex items-center absolute top-[16px] right-[14px] z-[2] sm:left-[12px]"
          >
            <input
              className="absolute cursor-pointer top-[0px] left-[0px] w-full h-full opacity-0 z-[2]"
              type="checkbox"
              name={`addToMarkAsRead[${notification.notification_id}]`}
              ref={inputRef}
              onChange={(e) =>
                readNotification(e, notification.notification_id)
              }
              defaultChecked
            />
            <span className='custom__checkbox__DE inline-block w-[16px] h-[16px] rounded-full border border-[#CCCCCC]/80 duration-500 before:content-[""] before:w-[8px] before:h-[8px] before:rounded-full before:bg-white before:absolute before:top-[50%] before:left-[50%] before:translate-y-[-50%] before:translate-x-[-50%] before:opacity-0' />
          </div>
        </Tippy>
        <div className="contant__cn w-[calc(100%_-_37px)] pl-[10px]">
          {isParent && (
            <h5 className="text-[16px] leading-[20px] text-black font-biotif__Regular">
              {(leads || contacts || accounts)?.name ||
                related_activities?.topic ||
                (notification?.notification?.message?.timeline?.action ===
                  'Deleted' &&
                  notification?.notification?.message?.changes[0]?.newValue
                    ?.value)}
            </h5>
          )}
          <ModuleRecordActionType notification={notification} />
          <RenderMessage
            data={notification}
            showMoreHandler={showMoreHandler}
            showMore={showMore}
          />
          <span className="text-[12px] leading-[18px] text-black/50 font-biotif__Regular inline-block">
            <DateTimeSince
              date={notification?.created_at}
              key={`${new Date(
                notification?.created_at
              ).getTime()}_notification`}
              tooltipEnable={false}
            />
          </span>
        </div>
      </div>
    </>
  );
};

export default memo(NotificationItems);
