import AlertModal from 'components/Modal/AlertModal';
import { useCallbackPrompt } from 'hooks/useCallbackPrompt';

interface Props {
  isDirtyCondition: boolean;
  onSave?: () => void;
  saveBtnLoading?: boolean;
  submitButtonText?: string;
  promptMessage?: string;
}

const RouteChangeConformationModal = ({
  isDirtyCondition,
  onSave,
  saveBtnLoading,
  submitButtonText = 'Proceed',
  promptMessage,
}: Props) => {
  const { showPrompt, confirmNavigation, cancelNavigation } =
    useCallbackPrompt(isDirtyCondition);

  const onEdit = async () => {
    await onSave?.();
    confirmNavigation();
  };

  return (
    <AlertModal
      title="Discard"
      visible={showPrompt}
      onClose={cancelNavigation}
      onCancel={cancelNavigation}
      onSubmit={confirmNavigation}
      width="800px"
      submitButtonText={submitButtonText}
      submitButtonClass="delete__Btn"
      {...(onSave && {
        onEdit,
        editButtonText: 'Save',
        editLoading: saveBtnLoading,
      })}
    >
      <p className="confirmation__title">
        {promptMessage ||
          `The changes you have made won't be saved, do you want to Proceed ?`}
      </p>
    </AlertModal>
  );
};

export default RouteChangeConformationModal;
