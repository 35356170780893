/* eslint-disable @typescript-eslint/no-unused-vars */
import FollowersList from '../FollowingBody/FollowersList';
import NotificationList from './NotificationList';
import { useNotificationService } from '../useNotificationService';
import Button from 'components/Button';
import { useSelector } from 'react-redux';
import { getHasMoreNotification, getNotificationsData } from 'redux/slices/notificationSlice';
import { useState } from 'react';

type Props = {
  showFollowers: boolean;
  visible: number;
  getMoreData: () => void;
  isLoading: boolean;
};

const NotificationBody = (props: Props) => {
  const {
    showFollowers,
    visible,
    getMoreData,
    isLoading
  } = props;
  const hashMore = useSelector(getHasMoreNotification)
  const notification = useSelector(getNotificationsData);
  const { refreshNotification } = useNotificationService();
  // ** states **

  return (
    <>
      <div
        className={`notification__body border-t border-t-[#CCCCCC]/20 overflow-y-auto ip__FancyScroll ${showFollowers ? 'hidden' : ''
          } ${notification?.length && notification?.length > visible ? 'max-h-[calc(100vh_-_110px)]' : 'max-h-[calc(100vh_-_54px)]'}`}
        id="notification_infiniteScroll"
      >
        <NotificationList visible={visible} />
      </div>
      {notification?.length && notification?.length > visible && !showFollowers && (
        <div className="see__all__btn__wrapper p-[14px] flex items-center justify-center">
          <Button
            onClick={getMoreData}
            isLoading={isLoading}
            className="primary__Btn font-biotif__Regular text-[14px] px-[22px] py-[8px] transition-all duration-300"
          >
            Load More
          </Button>
        </div>
      )}
      <div
        className={`notification__body following__body ${showFollowers ? '' : 'hidden'
          }`}
      >
        <FollowersList />
      </div>
    </>
  );
};

export default NotificationBody;
