import {
  AuthProviderConnectURLS,
  TokenProvider,
} from 'pages/Setting/email-setting/EmailSetting/types/userToken.type';

export const CALENDAR_PROVIDER_CONNECTION_URLS = [
  {
    token_provider: 'google_calendar',
    providerValue: {
      value: TokenProvider.GOOGLE_CALENDAR,
      urlValue: AuthProviderConnectURLS.google,
    },
  },
  {
    token_provider: 'outlook_calendar',
    providerValue: {
      value: TokenProvider.OFFICE_365_CALENDAR,
      urlValue: AuthProviderConnectURLS.microsoft,
    },
  },
];
